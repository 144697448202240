import Service from '@/modules/core/services'

class RecommendationsService extends Service {
  private RESOURCE = '/v1/recommendations'

  async chatInteraction(body: object) {
    const url = '/v2/recommendations/lead/chat-interaction'

    const method = 'POST'

    return await useAsyncData(() => this.call(method, url, body))
  }

  async getAddressByZipCode(zipCode: string = '') {
    const url = `${this.RESOURCE}/district/${zipCode}`

    const method = 'GET'

    const response = await this.call(method, url)

    if (response) {
      delete response.lng
      delete response.lat
    }

    return response
  }

  async getOrderHistory() {
    const { customer } = useCustomer()
    const { accessToken } = useAuth()

    const url = `${this.RESOURCE}/users/${customer.value.id}/orders`

    const method = 'GET'

    const header = {
      Authtoken: accessToken.value,
    }

    return await this.call(method, url, undefined, header)
  }
}

export default RecommendationsService
