import Service from '@/modules/core/services'

class MarketplaceService extends Service {
  private RESOURCE = '/v1/marketplace'

  async verifyLabToken(body: any) {
    const url = `${this.RESOURCE}/laboratories/check_tax_id`

    const method = 'POST'

    return await this.call(method, url, body)
  }
}

export default MarketplaceService
