import { snakeToCamel } from '@/utils/normalize'
import type { $Fetch, FetchOptions } from 'ofetch'

class Service {
  private $fetch: $Fetch

  constructor(fetcher: $Fetch) {
    this.$fetch = fetcher
  }

  call(
    method: string,
    url: string,
    data?: object,
    headers?: any,
    fetchOptions?: FetchOptions<'json'>,
    parse = true,
  ): Promise<any> {
    return this.$fetch(url, {
      headers,
      method,
      body: parse ? camelToSnake(data) : data,
      ...fetchOptions,
      parseResponse: response => snakeToCamel(JSON.parse(response)),
      server: false,
      lazy: true,
    })
  }
}

export default Service
