export enum FeatureFlag {
  EnableCardAuth = 'enable_card_auth',
}

export enum OrderStatus {
  Pending = 'Pending',
  Denied = 'Denied',
  Canceled = 'Canceled',
  Approved = 'Approved',
  Ready = 'Ready',
  Finished = 'Finished',
  Refunded = 'Refunded',
  Expired = 'Expired',
  Pending_But_Authorized = 'Pending_But_Authorized',
  Waiting_For_Refund = 'Waiting_For_Refund',
  Waiting_For_Results = 'Waiting_For_Results',
  Schedule_Confirmation = 'Schedule_Confirmation',
}

export enum EventName {
  PageView = 'trevo_page_view',
  ExamInput = 'trevo_exam_input',
  ExamSelect = 'trevo_exam_select',
  ExamRemove = 'trevo_exam_remove',
  Search = 'trevo_search',
  OpenCloseDetails = 'trevo_open_close_details',
  SelectBundle = 'trevo_select_bundle',
  SelectOrdenation = 'trevo_select_ordenation',
  SelectBranch = 'trevo_select_branch',
  SelectSchedule = 'trevo_select_schedule',
  AddCart = 'trevo_add_cart',
  OpenLoginModal = 'trevo_open_login_modal',
  SendToken = 'trevo_send_token',
  ChangePhone = 'trevo_change_phone',
  TokenVerified = 'trevo_token_verified',
  UpdateUser = 'trevo_update_user',
  RedirectToBillingAddress = 'trevo_redirect_to_billing_address',
  RedirectToMedicalRequest = 'trevo_redirect_to_medical_request',
  RedirectToPayment = 'trevo_redirect_to_payment',
  TokenCard = 'trevo_token_card',
  FinishOrder = 'trevo_finish_order',
  ErrorFinishOrder = 'trevo_error_finish_order',
  InitWppConversation = 'trevo_init_wpp_conversation',
  Error = 'trevo_error',
  OpenWppModal = 'trevo_open_wpp_modal',
  InitWppBundleCheckup = 'trevo_init_wpp_bundle_checkup',
  PaymentApproved = 'trevo_payment_approved',
  PaymentRejected = 'trevo_payment_rejected',
  CartExpired = 'trevo_cart_expired',
  ChangeLocation = 'trevo_change_location',
  OCRUpload = 'trevo_ocr_upload',
  ScheduleConfirmed = 'trevo_schedule_confirmed',
}

export enum Gender {
  Male = 'male',
  Female = 'female',
}
