export function isZipCode(zipCode: string): boolean {
  if (!zipCode || typeof zipCode !== 'string')
    return false

  const pattern = /^\d{5}-\d{3}$/

  return pattern.test(zipCode)
}

export function isZipCodePart(zipCodePart: string): boolean {
  if (!zipCodePart || typeof zipCodePart !== 'string')
    return false

  const pattern = /[0-9-]/

  return pattern.test(zipCodePart)
}

export function isLocationSlug(locationSlug: string): boolean {
  if (!locationSlug || typeof locationSlug !== 'string')
    return false

  const pattern = /^[a-z-]+-[a-z]{2}$/

  return pattern.test(locationSlug)
}

export function isCoords(coords: string) {
  if (!coords || typeof coords !== 'string')
    return false

  const pattern = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/

  return pattern.test(coords)
}

export function locationParamIsValid(location: string | string[]): boolean {
  if (!location || typeof location !== 'string')
    return false

  return (isZipCode(location) || isCoords(location) || isLocationSlug(location))
}

export function isUUID(uuid: string): boolean {
  if (!uuid)
    return false

  const pattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/

  return pattern.test(uuid)
}

export function isJWT(jwt: string): boolean {
  if (!jwt)
    return false

  // eslint-disable-next-line regexp/no-super-linear-backtracking, regexp/strict
  const jwtPattern = /^[\w-=]+\.[\w-=]+\.?[\w-.+/=]*$/

  return jwtPattern.test(jwt)
}
