import { EventName } from '@/modules/trevo/types/enum'

import useAuth from './useAuth'
import useAuthRefreshToken from './useAuthRefreshToken'

import type { ICustomer } from '../types/customer.d'

const genderOptions = [
  {
    text: 'Feminino',
    value: 'feminino',
  },
  {
    text: 'Feminino transgênero',
    value: 'feminino_trans',
  },
  {
    text: 'Masculino',
    value: 'masculino',
  },
  {
    text: 'Masculino transgênero',
    value: 'masculino_trans',
  },
  {
    text: 'Não binário',
    value: 'nao_binario',
  },
  {
    text: 'Outro',
    value: 'outro',
  },
]

function useCustomer() {
  const customer = useState<ICustomer>('Customer', () => ({}) as ICustomer)
  const loading = useState<boolean>('CustomerLoading', () => false)

  return {
    customer,
    loading,
    genderOptions,
  }
}

export default useCustomer

export function useCustomerGlobally() {
  const { customer, loading } = useCustomer()
  const { accessToken, showSignUpModal, signInModalWithoutGoogle } = useAuth()

  watch(customer, async (newCustomer, oldCustomer) => {
    if (newCustomer.id)
      await setUserIdOnTracking()

    if (newCustomer.picture) {
      signInModalWithoutGoogle.value = true

      return
    }

    if (!newCustomer.id)
      return

    if (newCustomer.exp)
      return

    if (
      !customer.value.name
      || !customer.value.email
      || !customer.value.birthDate
      || !customer.value.gender
    ) {
      showSignUpModal.value = true

      return
    }

    if (!oldCustomer.id) {
      return
    }

    if (oldCustomer.exp)
      return

    try {
      loading.value = true

      const baseURL = useRuntimeConfig().public.api.awsGateway

      await $fetch(`/v1/customer/users/${customer.value.id}`, {
        baseURL,
        method: 'PATCH',
        body: JSON.stringify(camelToSnake(customer.value)),
        headers: {
          Authtoken: accessToken.value,
        },
      })

      track(EventName.UpdateUser)

      await useAuthRefreshToken()

      showSignUpModal.value = false
    }
    catch {
      const { $toast } = useNuxtApp()

      $toast.error('Falha ao salvar dados do usuário.')
    }
    finally {
      loading.value = false
    }
  }, { deep: true })
}
