import useAuth from '@/modules/customer/composables/useAuth'
import useCustomer from '@/modules/customer/composables/useCustomer'
import { EventName } from '@/modules/trevo/types/enum'
import type { ICart } from '@/modules/cart/types/cart.d'
import useCart from './useCart'

const PromoError = {
  NOT_FOUND: 'E21017',
  ALREADY_BEEN_USED: 'E21018',
  CANT_USE_THIS_PROMO: 'E21019',
  MIN_PRICE_NOT_REACHED: 'E21021',
}

export default async (applyingCoupon = false) => {
  const { cart, loading, clear } = useCart()
  const { $toast } = useNuxtApp()

  try {
    loading.value = true

    const { isAuthenticated } = useAuth()

    if (!isAuthenticated.value)
      return

    if (sessionStorage.getItem('Cart'))
      sessionStorage.removeItem('Cart')

    const baseURL = useRuntimeConfig().public.api.awsGateway

    const { accessToken } = useAuth()

    const headers = {
      AuthToken: accessToken.value,
    }

    const { customer } = useCustomer()

    const response = await $fetch<ICart[]>(`/v1/recommendations/users/${customer.value.id}/cart`, {
      baseURL,
      method: 'PATCH',
      headers,
      body: JSON.stringify(camelToSnake(cart.value)),
    })

    if (!cart.value.id)
      cart.value = { ...cart.value, id: response[0].id }

    if (applyingCoupon) {
      $toast.success('Cupom aplicado com sucesso!')

      cart.value = snakeToCamel(response[0])

      usePromoModalClose()
    }
  }
  catch (error: any) {
    const { $toast } = useNuxtApp()

    if (error?.response?.status === 404) {
      if (useRoute().name?.toString().includes('checkout')) {
        useErrorMessage().value = 'Orçamento expirado, busque novos exames.'

        track(EventName.CartExpired)

        clear()

        return navigateTo('/perfil/historico-de-exames')
      }

      return null
    }

    if (error.data) {
      if (error.data?.error_code === PromoError.NOT_FOUND || error.data.error_code === PromoError.CANT_USE_THIS_PROMO) {
        $toast.error('Esse código não está mais em uso ou pode ter sido removido. Por favor, tente de novo.')

        return
      }
      else if (error.data?.error_code === PromoError.ALREADY_BEEN_USED) {
        $toast.error('Esse código já foi utilizado pelo usuário ou por uma conta vinculada à sua. Por favor, tente de novo.')

        return
      }
      else if (error.data.error_code === PromoError.MIN_PRICE_NOT_REACHED) {
        $toast.error('Esse código expirou. Por favor, tente de novo.')

        return
      }
    }

    $toast.error('Falha ao salvar detalhes do seu orçamento, tente novamente.')
  }
  finally {
    loading.value = false
  }
}
