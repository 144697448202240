import Service from '@/modules/core/services'
import type { ITokenCardOptions } from '@/modules/core/types'

class PagarmeService extends Service {
  private RESOURCE = '/core/v5'

  async createTokenCard(card: ITokenCardOptions) {
    const config = useRuntimeConfig()

    const url = `${this.RESOURCE}/tokens?appId=${config.public.pagarMe.token}`

    const method = 'POST'

    return await useAsyncData(() => this.call(method, url, card))
  }
}

export default PagarmeService
