import type { IInstallments } from '@/modules/cart/types/cart.d'
import useCart from './useCart'

export default async (asFunction = false, value: number = 0) => {
  try {
    const { cart, installments } = useCart()

    const totalCart = value || cart.value?.totalPrice - cart.value?.discount

    if (totalCart) {
      const baseURL = useRuntimeConfig().public.api.awsGateway
      const path = `/v1/sales-order/calculate-installments/${value || cart.value?.totalPrice - cart.value?.discount}`

      const response = await $fetch<IInstallments>(path, { baseURL })

      if (!asFunction) {
        installments.value = snakeToCamel(response)

        return
      }

      return snakeToCamel(response)
    }
    return []
  }
  catch {
    useNuxtApp().$toast.error('Falha ao atualizar parcelas de seu orçamento, tente novamente.')
  }
}
