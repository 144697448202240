export enum OrderStatus {
  Pending = 'Pending',
  Denied = 'Denied',
  Canceled = 'Canceled',
  Approved = 'Approved',
  Ready = 'Ready',
  Finished = 'Finished',
  Refunded = 'Refunded',
  Expired = 'Expired',
  Pending_But_Authorized = 'Pending_But_Authorized',
  Waiting_For_Refund = 'Waiting_For_Refund',
  Waiting_For_Results = 'Waiting_For_Results',
  Schedule_Confirmation = 'Schedule_Confirmation',
}

export enum LocalStorageKey {
  TEST_AB = 'testAB',
  PRESCRIPTION_HASHES = 'prescriptionHashes',
  GCLID = 'gclid',
}

export enum SessionStorageKey {
  OrderCreated = 'order_created',
}

export enum TestABGroups {
  CONTROL_AB_TEST = 'controlABTest',
  SHOW_OCR = 'showOCR',
  HIDE_WHATSAPP = 'hideWhatsapp',
  SHOW_CATEGORIES = 'showCategories',
  SHOW_SLUG_WITH_TAB_LIST = 'showSlugWithTabList',
  SHOW_SLUG_WITH_TAB_MAP = 'showSlugWithTabMap',
}

export enum UserIdentifiersEvent {
  CHAT_INTERACTION = 'chat_interaction',
  LOGIN = 'login',
  CHECKOUT = 'checkout',
}
