import Customer from '@/services/customer'
import Marketplace from '@/services/marketplace'

import Order from '@/services/order'
import Pagarme from '@/services/pagarme'
import Recommendations from '@/services/recommendations'
import { $fetch } from 'ofetch'
import type { FetchOptions } from 'ofetch'

interface IApiInstance {
  recommendations: Recommendations
  marketplace: Marketplace
  customer: Customer
  order: Order
  pagarme: Pagarme
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  const trevoOptions: FetchOptions = {
    baseURL: config.public.api.awsGateway,
  }

  const pagarmeOptions: FetchOptions = {
    baseURL: config.public.pagarMe.api,
  }

  const trevoFetcher = $fetch.create(trevoOptions)
  const pagarmeFetcher = $fetch.create(pagarmeOptions)

  const api: IApiInstance = {
    recommendations: new Recommendations(trevoFetcher),
    marketplace: new Marketplace(trevoFetcher),
    customer: new Customer(trevoFetcher),
    order: new Order(trevoFetcher),
    pagarme: new Pagarme(pagarmeFetcher),
  }

  return {
    provide: {
      api,
    },
  }
})
