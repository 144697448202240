import { CHECKOUT_STEPS } from '@/modules/checkout/types/checkout.enum'
import type { ICart, IInstallments, IPromo } from '@/modules/cart/types/cart.d'
import type { IBranch, IBundle } from '@/modules/procedure/types/bundle.d'

interface IStep {
  index: number
  title: string
  percent: number
  progress: boolean
  showTitle: boolean
}

const STEPS: IStep[] = [
  {
    index: 0,
    title: 'Resumo do pedido',
    percent: 0,
    progress: false,
    showTitle: true,
  },
  {
    index: 1,
    title: 'Dados pessoais',
    percent: 17,
    progress: true,
    showTitle: true,
  },
  {
    index: 2,
    title: 'Dados pessoais',
    percent: 17,
    progress: true,
    showTitle: true,
  },
  {
    index: 3,
    title: 'Disponibilidade de Horário',
    percent: 34,
    progress: true,
    showTitle: true,
  },
  {
    index: 4,
    title: 'Endereço de cobrança',
    percent: 51,
    progress: true,
    showTitle: true,
  },
  {
    index: 5,
    title: 'Pedido Médico',
    percent: 51,
    progress: true,
    showTitle: true,
  },
  {
    index: 6,
    title: 'Forma de Pagamento',
    percent: 68,
    progress: true,
    showTitle: true,
  },
  {
    index: 7,
    title: 'Pedido Realizado',
    percent: 100,
    progress: false,
    showTitle: false,
  },
  {
    index: 8,
    title: '',
    percent: 100,
    progress: false,
    showTitle: false,
  },
]

function useCart() {
  const cart = useState<ICart>('Cart', () => ({}) as ICart)
  const bundleSelected = useState<IBundle>('BundleSelected', () => ({}) as IBundle)
  const branchesSelected = useState<IBranch[]>('BranchesSelected', () => ({}) as IBranch[])
  const installments = useState<IInstallments>('Installments', () => ({}) as IInstallments)
  const coupons = useState<IPromo[]>('Coupons', () => [] as IPromo[])
  const step = useState<IStep>('CartStep', () => STEPS[0])
  const loading = useState<boolean>('CartLoading', () => false)
  const showApplyCouponDialog = useState<boolean>('ShowApplyCouponDialog', () => false)

  return {
    cart,
    bundleSelected,
    branchesSelected,
    installments,
    step,
    coupons,
    loading,
    showApplyCouponDialog,
    setStep(index: number) {
      step.value = STEPS[index]
    },
    steps: computed(() => {
      if (!Object.keys(cart.value).length)
        return []

      const steps = [
        { idx: 0, title: 'Selecionou', msg: 'Selecionou seus exames', icon: 'bi:hand-index-thumb' },
        { idx: 1, title: 'Comparou', msg: 'Comparou os orçamentos', icon: 'material-symbols:compare-arrows-rounded' },
        { idx: 2, title: 'Comprou', msg: 'Comprou seus exames', icon: 'tabler:shopping-cart-check' },
        { idx: 3, title: 'Agendar', msg: 'Agendar com Concierge', icon: 'material-symbols-light:calendar-clock-outline-rounded' },
        { idx: 4, title: 'Realizar', msg: 'Realizar exame', icon: 'material-symbols:lab-research-outline' },
      ]

      const needSchedule = cart.value.cartDetails.find(({ needSchedule }) => needSchedule)

      return needSchedule ? steps : steps.filter(({ idx }) => idx !== 3)
    }),
    clear() {
      cart.value = {} as ICart
      bundleSelected.value = {} as IBundle
      installments.value = {} as IInstallments
      coupons.value = [] as IPromo[]

      sessionStorage.removeItem('Cart')
      sessionStorage.removeItem('BundleSelected')
    },
  }
}

export function useCartGlobally() {
  const { cart, bundleSelected, showApplyCouponDialog } = useCart()

  watch(cart, (newCart: ICart, oldCart: ICart | undefined) => {
    if (!newCart)
      return

    if (!Object.keys(newCart).length)
      return

    if (!oldCart?.id && newCart.id)
      return

    if (!oldCart?.cartDetails?.length && !newCart?.cartDetails?.length)
      return

    let applyingCoupon = false

    if (newCart.coupon !== oldCart?.coupon)
      applyingCoupon = true

    if (newCart.lastStep === CHECKOUT_STEPS.FINISH)
      return

    useCartUpdateInstallments()
    useCartSave(applyingCoupon)
  }, { deep: true, immediate: true })

  onNuxtReady(() => {
    const { query } = useRoute()
    if (query.cupom) {
      showApplyCouponDialog.value = true
      cart.value = {
        ...cart.value,
        coupon: query.cupom as string,
      }
    }
    const bundleSelectedFromSession = sessionStorage.getItem('BundleSelected')

    if (bundleSelectedFromSession)
      bundleSelected.value = JSON.parse(bundleSelectedFromSession)

    if (Object.keys(cart.value).length)
      return

    const cartFromSession = sessionStorage.getItem('Cart')

    if (cartFromSession) {
      cart.value = JSON.parse(cartFromSession)

      return
    }

    useCartUpdate()
  })
}

export default useCart
