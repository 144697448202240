import { jwtDecode } from 'jwt-decode'
import useCustomer from './useCustomer'
import type { ICustomer } from '../types/customer'

function useAuth() {
  const accessToken = useState('CustomerAccessToken', () => '')
  const refreshToken = useState('CustomerRefreshToken', () => '')

  const loading = useState('AuthLoading', () => false)

  const showSignInModal = useState('ShowSignInModal', () => false)
  const showSignUpModal = useState('ShowSignUpModal', () => false)
  const signInModalWithoutGoogle = useState('SignInModalWithoutGoogle', () => false)

  return {
    accessToken,
    refreshToken,
    isAuthenticated: computed(() => !!accessToken.value),
    showSignInModal,
    showSignUpModal,
    signInModalWithoutGoogle,
    loading,
    logout() {
      accessToken.value = ''
      refreshToken.value = ''

      localStorage.removeItem('AccessToken')
      localStorage.removeItem('RefreshToken')

      const { customer } = useCustomer()

      customer.value = {} as ICustomer

      showSignUpModal.value = false

      if (useRoute().name?.toString().includes('checkout'))
        return navigateTo('/checkout')

      return navigateTo('/')
    },
  }
}

export default useAuth

export function useAuthGlobally() {
  const { accessToken, refreshToken, signInModalWithoutGoogle, showSignInModal } = useAuth()
  const { customer } = useCustomer()

  onNuxtReady(() => {
    const accessTokenFromLocalStorage = localStorage.getItem('AccessToken')
    const refreshTokenFromLocalStorage = localStorage.getItem('RefreshToken')

    if (accessTokenFromLocalStorage && !accessToken.value)
      accessToken.value = accessTokenFromLocalStorage

    if (refreshTokenFromLocalStorage && !refreshToken.value)
      refreshToken.value = refreshTokenFromLocalStorage
  })

  watch(accessToken, (newAccessToken) => {
    if (!newAccessToken)
      return

    localStorage.setItem('AccessToken', newAccessToken)
    localStorage.setItem('RefreshToken', refreshToken.value)

    if (customer.value.picture) {
      const customerFromToken = snakeToCamel(jwtDecode(newAccessToken))

      delete customerFromToken.exp

      customer.value = {
        ...customerFromToken,
        name: customer.value.name,
        lastName: customer.value.lastName,
        email: customer.value.email,
      }
    }
    else {
      customer.value = snakeToCamel(jwtDecode(newAccessToken))

      useCustomerUpdate()
    }
  }, { immediate: true })

  watch(showSignInModal, (newShowSignInModal) => {
    if (!newShowSignInModal) {
      signInModalWithoutGoogle.value = false
    }
  })
}
