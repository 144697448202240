import useAuth from './useAuth'

import useCustomer from './useCustomer'
import type { ICustomer } from '../types/customer.d'

export default async () => {
  const { accessToken, refreshToken, logout } = useAuth()
  const { loading, customer } = useCustomer()

  try {
    loading.value = true

    const baseURL = useRuntimeConfig().public.api.awsGateway
    const response = await $fetch<ICustomer>(`/v1/customer/users/${customer.value.id}`, {
      baseURL,
      headers: {
        AuthToken: accessToken.value,
      },
    })

    customer.value = snakeToCamel(response)
  }
  catch (error: any) {
    const { $toast } = useNuxtApp()

    if (error.response.status === 403) {
      if (refreshToken.value) {
        useAuthRefreshToken()

        return
      }

      $toast.error('Sessão expirada, faça login novamente.')

      logout()

      return
    }

    $toast.error('Falha ao buscar dados do usuário, tente novamente.')
  }
  finally {
    loading.value = false
  }
}
