import { LocalStorageKey, TestABGroups } from '@/modules/core/enums'

const tests: TestABGroups[] = [
  TestABGroups.HIDE_WHATSAPP,
  TestABGroups.SHOW_CATEGORIES,
  TestABGroups.SHOW_SLUG_WITH_TAB_LIST,
  TestABGroups.SHOW_SLUG_WITH_TAB_MAP,
]

function _determineTestGroup(): number {
  return Math.floor(Math.random() * 10)
}

export default function (): string {
  if (!import.meta.client)
    return ''

  const testAB = localStorage.getItem(LocalStorageKey.TEST_AB) as TestABGroups

  if (testAB && (tests.includes(testAB as TestABGroups) || testAB === TestABGroups.CONTROL_AB_TEST)) {
    return testAB
  }

  const value = tests[_determineTestGroup()] || TestABGroups.CONTROL_AB_TEST

  localStorage.setItem(LocalStorageKey.TEST_AB, value)

  return value
}
