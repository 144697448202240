export function snakeToCamel(input: any): any {
  if (typeof input !== 'object' || input === null)
    return input

  if (Array.isArray(input))
    return input.map(item => snakeToCamel(item))

  const camelObject: { [key: string]: any } = {}

  for (const key in input) {
    if (Object.prototype.hasOwnProperty.call(input, key)) {
      const camelKey = key.replace(/_(\w)/g, (_, p1) => p1.toUpperCase())
      camelObject[camelKey] = snakeToCamel(input[key])
    }
  }

  return camelObject
}

export function camelToSnake(input: any): any {
  if (typeof input !== 'object' || input === null)
    return input

  if (Array.isArray(input))
    return input.map(item => camelToSnake(item))

  const snakeObject: { [key: string]: any } = {}

  for (const key in input) {
    if (Object.prototype.hasOwnProperty.call(input, key)) {
      const snakeKey = key.replace(
        /[A-Z]/g,
        match => `_${match.toLowerCase()}`,
      )
      snakeObject[snakeKey] = camelToSnake(input[key])
    }
  }

  return snakeObject
}
