import type { ISessionTokens } from '@/modules/core/types'
import useAuth from './useAuth'

export default async () => {
  const { refreshToken, accessToken } = useAuth()

  const baseURL = useRuntimeConfig().public.api.awsGateway

  let response = await $fetch<ISessionTokens>('/v1/customer/users/refresh_token', {
    method: 'POST',
    baseURL,
    body: JSON.stringify(camelToSnake({ refreshToken: refreshToken.value })),
  })

  response = snakeToCamel(response)

  accessToken.value = response.accessToken
  refreshToken.value = response.refreshToken
}
