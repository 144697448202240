import Service from '@/modules/core/services'

class OrderService extends Service {
  private RESOURCE = '/v1/sales-order'

  async sendSMSToIdentifyUser(body: any) {
    const url = `${this.RESOURCE}/orders/request_order_confirmation_token`

    const method = 'POST'

    return await useAsyncData(() => this.call(method, url, body))
  }

  async sendSMSToIdentifyUserOnLab(body: any) {
    const url = `${this.RESOURCE}/request_order_confirmation_token`

    const method = 'POST'

    return await this.call(method, url, body)
  }

  async ordersByLabForCustomer(
    labToken: string,
    customerToken: string,
    phone: string,
  ) {
    const url = `${this.RESOURCE}/orders_by_lab_for_customer?lab_token=${labToken}&customer_token=${customerToken}&phone=${phone}`

    const method = 'GET'

    return await this.call(method, url)
  }

  async releaseExam(id: string = '') {
    const url = `${this.RESOURCE}/orders/details/${id}/status`

    const method = 'POST'

    const body = {
      trigger: 'finish',
    }

    return await this.call(method, url, body)
  }

  async getCancellationReasons() {
    const url = `${this.RESOURCE}/cancellation-reasons?order_by=requires_explanation`

    const { accessToken } = useAuth()

    const method = 'GET'

    const headers = {
      Authtoken: accessToken.value,
    }

    return await this.call(method, url, undefined, headers)
  }

  async cancelOrder(orderId: string, payload: any) {
    const { accessToken } = useAuth()
    const { customer } = useCustomer()

    const url = `/v1/customer/users/${customer.value.id}/orders/${orderId}/refund`

    const method = 'POST'

    const headers = {
      Authtoken: accessToken.value,
    }

    return await this.call(method, url, payload, headers)
  }
}

export default OrderService
