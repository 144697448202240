import Service from '@/modules/core/services'

import type {
  IBillingAddress,
  IGoogleUserInfo,
  ISessionTokens,
  IUser,
} from '@/modules/core/types'

class CustomerService extends Service {
  private RESOURCE = '/v1/customer/users'

  async requestPhoneCode(body: { phone: string, sessionId: string }) {
    const url = `${this.RESOURCE}/request_phone_code`

    const method = 'POST'

    return await useAsyncData(() => this.call(method, url, body))
  }

  async verifyPhoneCode(body: any) {
    const url = `${this.RESOURCE}/verify_phone_code`

    const method = 'POST'

    return await this.call(method, url, body)
  }

  async saveUser(id: string = '', body: IUser) {
    const url = `${this.RESOURCE}/${id}`

    const { accessToken } = useAuth()

    const method = 'PATCH'

    const header = {
      Authtoken: accessToken.value,
    }

    const response = await this.call(method, url, body, header)

    return response
  }

  async LaboratorySaveUser(id: string = '', body: IUser, token: string) {
    const url = `${this.RESOURCE}/${id}`

    const method = 'PATCH'

    const header = {
      Authtoken: token,
    }

    const response = await this.call(method, url, body, header)

    return response
  }

  async getUser(id: string = '') {
    const url = `${this.RESOURCE}/${id}`

    const method = 'GET'

    const { accessToken } = useAuth()

    const header = {
      Authtoken: accessToken.value,
    }

    return await this.call(method, url, undefined, header)
  }

  async createUser(body: object) {
    const url = this.RESOURCE

    const method = 'POST'

    return await useAsyncData(() => this.call(method, url, body))
  }

  async getAddress(userId: string): Promise<IBillingAddress> {
    const url = `${this.RESOURCE}/${userId}/address`

    const method = 'GET'

    const { accessToken } = useAuth()

    const header = {
      Authtoken: accessToken.value,
    }

    return await this.call(method, url, undefined, header)
  }

  async updateAddress(userID: string = '', address: any) {
    const url = `${this.RESOURCE}/${userID}/address`

    const method = 'PATCH'

    const { accessToken } = useAuth()

    const header = {
      Authtoken: accessToken.value,
    }

    const { status } = await useAsyncData(() =>
      this.call(method, url, address, header),
    )

    if (status.value !== 'success')
      throw new Error('Error: update address')
  }

  async updateOrderStatus(userId: string, orderId: string) {
    const url = `${this.RESOURCE}/${userId}/orders/${orderId}`

    const method = 'GET'

    const { accessToken } = useAuth()

    const headers = {
      Authtoken: accessToken.value,
    }

    return await this.call(method, url, undefined, headers)
  }

  authGoogle(credential = '') {
    const url = `${this.RESOURCE}/google/auth`

    const method = 'POST'

    const body = { credential }

    return useAsyncData<ISessionTokens | IGoogleUserInfo>(() =>
      this.call(method, url, body),
    )
  }
}

export default CustomerService
